/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faRefresh,
  faXmark as faCancel,
  faDownload,
  faPenToSquare,
  faTrashCan,
  faFloppyDisk,
  faRotateLeft,
  faMagnifyingGlass,
  faClose,
  faCheck,
  faRotate,
  faSignIn,
  faSignOut,
  faCopy,
  faBell,
  faSterlingSign,
  faArrowRight,
  faFileImport,
  faFileInvoice,
  faThumbsUp,
  faThumbsDown,
  faFilePdf,
  faEnvelope,
  faHippo,
  faScrewdriverWrench
} from '@fortawesome/free-solid-svg-icons';

DefaultButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  noText: PropTypes.bool,
  dynamicWidth: PropTypes.bool
};

DefaultButton.defaultProps = {
  type: 'button',
  text: 'Button',
  className: '',
  disabled: false,
  isLoading: false,
  onClick: () => {},
  noText: false,
  dynamicWidth: false
};

function DefaultButton({ onClick, text, type, className, disabled, isLoading, noText, dynamicWidth }) {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`default-button ${dynamicWidth ? 'dynamic-width' : 'fixed-width'} ${
        isLoading ? 'button--loading' : ''
      } ${className}`}
    >
      {text.toLowerCase() === 'refresh' ? (
        <span className={`button__text whitespace-nowrap ${noText ? 'mr-0' : 'mr-1'}`}>
          <FontAwesomeIcon icon={faRefresh} size="lg" />
        </span>
      ) : text.toLowerCase() === 'retry' ? (
        <span className={`button__text whitespace-nowrap ${noText ? 'mr-0' : 'mr-1'}`}>
          <FontAwesomeIcon icon={faRotate} size="lg" />
        </span>
      ) : text.toLowerCase() === 'export' ||
        text.toLowerCase() === 'download' ||
        text.toLowerCase() === 'invoices' ||
        text.toLowerCase() === 'sage' ? (
        <span className={`button__text whitespace-nowrap ${noText ? 'mr-0' : 'mr-1'}`}>
          <FontAwesomeIcon icon={faDownload} size="lg" />
        </span>
      ) : text.toLowerCase() === 'create' || text.toLowerCase() === 'add' || text.toLowerCase() === 'new field' ? (
        <span className={`button__text whitespace-nowrap ${noText ? 'mr-0' : 'mr-1'}`}>
          <FontAwesomeIcon icon={faPlus} size="lg" />
        </span>
      ) : text.toLowerCase() === 'edit' || text.toLowerCase() === 'update' ? (
        <span className={`button__text whitespace-nowrap ${noText ? 'mr-0' : 'mr-1'}`}>
          <FontAwesomeIcon icon={faPenToSquare} size="lg" />
        </span>
      ) : text.toLowerCase() === 'delete' ? (
        <span className={`button__text whitespace-nowrap ${noText ? 'mr-0' : 'mr-1'}`}>
          <FontAwesomeIcon icon={faTrashCan} size="lg" />
        </span>
      ) : text.toLowerCase() === 'save' || text.toLowerCase() === 'book' ? (
        <span className={`button__text whitespace-nowrap ${noText ? 'mr-0' : 'mr-1'}`}>
          <FontAwesomeIcon icon={faFloppyDisk} size="lg" />
        </span>
      ) : text.toLowerCase() === 'discard' || text.toLowerCase() === 'reset' || text.toLowerCase() === 'reset' ? (
        <span className={`button__text whitespace-nowrap ${noText ? 'mr-0' : 'mr-1'}`}>
          <FontAwesomeIcon icon={faRotateLeft} size="lg" />
        </span>
      ) : text.toLowerCase() === 'search' ? (
        <span className={`button__text whitespace-nowrap ${noText ? 'mr-0' : 'mr-1'}`}>
          <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
        </span>
      ) : text.toLowerCase() === 'close' || text.toLowerCase() === 'cancel' ? (
        <span className={`button__text whitespace-nowrap ${noText ? 'mr-0' : 'mr-1'}`}>
          <FontAwesomeIcon icon={faClose} size="lg" />
        </span>
      ) : text.toLowerCase() === 'logout' ? (
        <span className={`button__text whitespace-nowrap ${noText ? 'mr-0' : 'mr-1'}`}>
          <FontAwesomeIcon icon={faSignOut} size="lg" />
        </span>
      ) : text.toLowerCase() === 'skip' ? (
        <span className={`button__text whitespace-nowrap ${noText ? 'mr-0' : 'mr-2'}`}>
          <FontAwesomeIcon icon={faArrowRight} />
        </span>
      ) : text.toLowerCase() === 'import' ? (
        <span className={`button__text whitespace-nowrap ${noText ? 'mr-0' : 'mr-2'}`}>
          <FontAwesomeIcon icon={faFileImport} size="lg" />
        </span>
      ) : text.toLowerCase() === 'send' ? (
        <span className={`button__text whitespace-nowrap ${noText ? 'mr-0' : 'mr-2'}`}>
          <FontAwesomeIcon icon={faEnvelope} size="lg" />
        </span>
      ) : text.toLowerCase() === 'maintenance mode active' ? (
        <span className={`button__text whitespace-nowrap ${noText ? 'mr-0' : 'mr-2'}`}>
          <FontAwesomeIcon icon={faScrewdriverWrench} size="lg" />
        </span>
      ) : null}
      {!noText && <span className="button__text whitespace-nowrap">{text}</span>}
    </button>
  );
}

DefaultLink.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  state: PropTypes.object
};

DefaultLink.defaultProps = {
  text: 'Button',
  to: '/',
  className: '',
  state: null
};

function DefaultLink({ text, to, className, state }) {
  return (
    <Link
      to={to}
      state={state}
      className={`rounded border border-gray-02 px-10 py-[10px] text-paragraph-small font-semibold text-primary-black transition-all duration-100 hover:bg-gray-02/20 md:px-9 md:py-[14px] md:text-paragraph ${className}`}
    >
      {text.toLowerCase() === 'refresh' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faRefresh} size="lg" />
        </span>
      ) : text.toLowerCase() === 'export' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faDownload} size="lg" />
        </span>
      ) : text.toLowerCase() === 'create' || text.toLowerCase() === 'add' || text.toLowerCase() === 'new field' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faPlus} size="lg" />
        </span>
      ) : text.toLowerCase() === 'edit' || text.toLowerCase() === 'update' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faPenToSquare} size="lg" />
        </span>
      ) : text.toLowerCase() === 'delete' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faTrashCan} size="lg" />
        </span>
      ) : text.toLowerCase() === 'save' || text.toLowerCase() === 'book' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faFloppyDisk} size="lg" />
        </span>
      ) : text.toLowerCase() === 'discard' || text.toLowerCase() === 'reset' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faRotateLeft} size="lg" />
        </span>
      ) : text.toLowerCase() === 'search' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
        </span>
      ) : text.toLowerCase() === 'close' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faClose} size="lg" />
        </span>
      ) : text.toLowerCase() === 'send' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faEnvelope} size="lg" />
        </span>
      ) : null}{' '}
      {text}
    </Link>
  );
}

PrimaryButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  hideLabel: PropTypes.bool,
  dynamicWidth: PropTypes.bool,
  children: PropTypes.node,
  icon: PropTypes.object
};

PrimaryButton.defaultProps = {
  type: 'button',
  text: 'Button',
  className: '',
  disabled: false,
  id: '',
  readOnly: false,
  onClick: () => {},
  hideLabel: false,
  children: null,
  dynamicWidth: false,
  icon: null
};

function PrimaryButton({
  onClick,
  text,
  type,
  className,
  disabled,
  id,
  readOnly,
  hideLabel,
  children,
  dynamicWidth,
  icon
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`button ${dynamicWidth ? 'dynamic-width' : 'fixed-width'} ${
        disabled ? 'button--loading' : ''
      } ${className} ${readOnly ? '!bg-opacity-70' : ''}`}
      readOnly={readOnly}
      id={id}
    >
      {text.toLowerCase() === 'refresh' || text.toLowerCase() === 'reset password' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faRefresh} size="lg" />
        </span>
      ) : text.toLowerCase() === 'export' || text.toLowerCase() === 'download' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faDownload} size="lg" />
        </span>
      ) : text.toLowerCase() === 'create' ||
        text.toLowerCase() === 'add' ||
        text.toLowerCase() === 'add company' ||
        text.toLowerCase() === 'new field' ||
        text.toLowerCase() === 'add account' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faPlus} size="lg" />
        </span>
      ) : text.toLowerCase() === 'confirm' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faCheck} size="lg" />
        </span>
      ) : text.toLowerCase() === 'edit' || text.toLowerCase() === 'update' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faPenToSquare} size="lg" />
        </span>
      ) : text.toLowerCase() === 'delete' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faTrashCan} size="lg" />
        </span>
      ) : text.toLowerCase() === 'save' || text.toLowerCase() === 'book' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faFloppyDisk} size="lg" />
        </span>
      ) : text.toLowerCase() === 'discard' || text.toLowerCase() === 'reset' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={icon || faRotateLeft} size="lg" />
        </span>
      ) : text.toLowerCase() === 'search' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
        </span>
      ) : text.toLowerCase() === 'search' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
        </span>
      ) : text.toLowerCase() === 'close' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faClose} size="lg" />
        </span>
      ) : text.toLowerCase() === 'copy' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faCopy} size="lg" />
        </span>
      ) : text.toLowerCase() === 'cancel' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faCancel} size="lg" />
        </span>
      ) : text.toLowerCase() === 'login' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faSignIn} size="lg" className="max-w-[25px]" />
        </span>
      ) : text.toLowerCase() === 'logout' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faSignOut} size="lg" className="text-xl" />
        </span>
      ) : text.toLowerCase() === 'activity' ? (
        <span className="button__text whitespace-nowrap">
          <FontAwesomeIcon icon={faBell} size="lg" />
        </span>
      ) : text.toLowerCase() === 'pay' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faSterlingSign} />
        </span>
      ) : text.includes('Import') ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faFileImport} size="lg" />
        </span>
      ) : text.toLowerCase() === 'ok' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faCheck} />
        </span>
      ) : text === 'Approve' ? (
        <span className="button__text whitespace-nowrap !-ml-1">
          <FontAwesomeIcon icon={faThumbsUp} size="lg" />
        </span>
      ) : text === 'Unapprove' ? (
        <span className="button__text whitespace-nowrap !-ml-1">
          <FontAwesomeIcon icon={faThumbsDown} size="lg" />
        </span>
      ) : text.includes('Unapprove') ? (
        <span className="button__text whitespace-nowrap !-ml-1">
          <FontAwesomeIcon icon={faThumbsUp} size="lg" />
        </span>
      ) : text.includes('Approve') ? (
        <span className="button__text whitespace-nowrap !-ml-1">
          <FontAwesomeIcon icon={faThumbsDown} size="lg" />
        </span>
      ) : text.toLowerCase() === 'invoice' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faFileInvoice} size="lg" />
        </span>
      ) : text.toLowerCase() === 'view' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faFilePdf} size="lg" />
        </span>
      ) : text.toLowerCase() === 'send' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faEnvelope} size="lg" />
        </span>
      ) : text.toLowerCase() === 'set' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faHippo} size="lg" />
        </span>
      ) : text.toLowerCase() === 'maintenance mode active' ? (
        <span className={`button__text whitespace-nowrap ${hideLabel ? 'mr-0' : 'mr-2'}`}>
          <FontAwesomeIcon icon={faScrewdriverWrench} size="lg" />
        </span>
      ) : null}{' '}
      {!hideLabel && <span className="button__text whitespace-nowrap mr-1">{text}</span>}
      {children}
    </button>
  );
}

PrimaryLink.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  state: PropTypes.object,
  view: PropTypes.bool
};

PrimaryLink.defaultProps = {
  text: 'Link',
  to: '/',
  className: '',
  state: null
};

function PrimaryLink({ text, to, className, state, view }) {
  return (
    <Link
      to={to}
      state={state}
      className={`rounded border ${
        view
          ? 'border-primary-green bg-primary-green hover:border-primary-green-hover hover:bg-primary-green-hover'
          : 'border-primary-red bg-primary-red hover:border-primary-red-hover hover:bg-primary-red-hover'
      }  px-6 py-[10px] text-paragraph-small font-semibold text-white md:px-9 md:py-[16px] md:text-paragraph 
         ${className} transition-all duration-100`}
    >
      {text.toLowerCase() === 'refresh' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faRefresh} size="lg" />
        </span>
      ) : text.toLowerCase() === 'export' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faDownload} size="lg" />
        </span>
      ) : text.toLowerCase() === 'create' || text.toLowerCase() === 'add' || text.toLowerCase() === 'new field' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faPlus} size="lg" />
        </span>
      ) : text.toLowerCase() === 'edit' || text.toLowerCase() === 'update' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faPenToSquare} size="lg" />
        </span>
      ) : text.toLowerCase() === 'delete' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faTrashCan} size="lg" />
        </span>
      ) : text.toLowerCase() === 'save' || text.toLowerCase() === 'book' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faFloppyDisk} size="lg" />
        </span>
      ) : text.toLowerCase() === 'discard' || text.toLowerCase() === 'reset' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faRotateLeft} size="lg" />
        </span>
      ) : text.toLowerCase() === 'close' || text.toLowerCase() === 'reset' ? (
        <span className="button__text whitespace-nowrap mr-1">
          <FontAwesomeIcon icon={faClose} size="lg" />
        </span>
      ) : null}{' '}
      {text}
    </Link>
  );
}

export { DefaultButton, PrimaryButton, PrimaryLink, DefaultLink };
